import React, {useState,useContext} from 'react';

import {Link} from 'react-router-dom';

import '../../index.css';
import {MyContext} from '../login/MyContext';




function Menu(props) {

    const {user, logout} = useContext(MyContext);
    const [dropdown, setDropdown]=useState(false);

  const abrirCerrarDropdown=()=>{
    setDropdown(!dropdown);
  }
    
  return (
<>
    <div id="sidebar" className="app-sidebar">

    <div className="app-sidebar-content" data-scrollbar="true" data-height="100%">
    
    <div className="menu">
    <div className="menu-profile">
    <a href="javascript:;" className="menu-profile-link" data-toggle="app-sidebar-profile" data-target="#appSidebarProfileMenu">
    <div className="menu-profile-cover with-shadow"></div>
    <center><div className="menu-profile-image">
    <img src="../assets/img/user/user-13.jpg" alt="" />
    </div></center>
    <div className="menu-profile-info">
    <div className="d-flex align-items-center">
    <div className="flex-grow-1">
    <a href="#"  style={{textDecoration:"none", color:"white"}}>{user.name}</a>
    </div>
    <div className="menu-caret ms-auto"></div>
    </div>
    <small>{user.documento}</small>
    </div>
    </a>
    </div>
   {/* <div id="appSidebarProfileMenu" className="collapse">
    <div className="menu-item pt-5px">
    <a href="javascript:;" className="menu-link">
    <div className="menu-icon"><i className="fa fa-cog"></i></div>
    <div className="menu-text">Settings</div>
    </a>
    </div>
    <div className="menu-item">
    <a href="javascript:;" className="menu-link">
    <div className="menu-icon"><i className="fa fa-pencil-alt"></i></div>
    <div className="menu-text"> Send Feedback</div>
    </a>
    </div>
    <div className="menu-item pb-5px">
    <a href="javascript:;" className="menu-link" >
    <div className="menu-icon"><i className="fa fa-question-circle"></i></div>
    <div className="menu-text"> Cerrar Sesión</div>
    </a>
    </div>
    <div className="menu-divider m-0"></div>
    </div> */}
    <div className="menu-header">Menu</div>
  

    { user.tipo_usuario =="admin"
     ?<>
    <div class="menu-item has-sub active">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-sitemap"></i>
</div>
<div class="menu-text">Gemconsoft</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Demo" className="menu-link">
<div class="menu-text"> <i class="fas fa-wallet" style={{color:"#4FAEFC"}}></i> &nbsp;Solicitudes demo</div>
</Link>
<Link to="/Crear_publicidad" className="menu-link">
<div class="menu-text"> <i class="fas fa-business-time" style={{color:"#4FAEFC"}}></i> &nbsp;Publicidad</div>
</Link>
<Link to="/Empresas" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-shield" style={{color:"#4FAEFC"}}></i> &nbsp;Empresas</div>
</Link>
</div>



</div>
</div>
        
</>

        : ""
        }   
      

      { user.tipo_usuario =="empresa"
     ?<>
    <div class="menu-item has-sub active">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-cogs"></i>
</div>
<div class="menu-text">Administrador</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Usuarios" className="menu-link">
<div class="menu-text"> <i class="fas fa-users" style={{color:"#FFC886"}}></i> &nbsp;Craer usuarios</div>
</Link>

</div>



</div>
</div>
        
</>

        : ""
        }  




{ user.tipo_usuario =="empresa" || user.tipo_usuario =="empleado" 
     ?<>
    <div class="menu-item has-sub">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-sitemap"></i>
</div>
<div class="menu-text">Configuración</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Terceros</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Cuentas</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Cuentas asociadas</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/Centros_costos" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Centros de costos</div>
</Link>

</div>



</div>
</div>
        
</>

        : ""
        }  




{ user.tipo_usuario =="empresa" || user.tipo_usuario =="empleado" 
     ?<>
    <div class="menu-item has-sub">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-shopping-cart"></i>
</div>
<div class="menu-text">Compras</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Cotización</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Orden de compras</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Remisión</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Factura</div>
</Link>

</div>


<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Factura directa</div>
</Link>

</div>


<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Nota crédito</div>
</Link>

</div>



</div>
</div>
        
</>

        : ""
        } 




{ user.tipo_usuario =="empresa" || user.tipo_usuario =="empleado" 
     ?<>
    <div class="menu-item has-sub">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-credit-card"></i>
</div>
<div class="menu-text">Ventas</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Cotización</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Pedido</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Remisión</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Factura</div>
</Link>

</div>


<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Factura directa</div>
</Link>

</div>


<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Factura POS</div>
</Link>

</div>


<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Nota crédito</div>
</Link>

</div>



</div>
</div>
        
</>

        : ""
        } 


{ user.tipo_usuario =="empresa" || user.tipo_usuario =="empleado" 
     ?<>
    <div class="menu-item has-sub">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-archive"></i>
</div>
<div class="menu-text">Inventario</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Productos</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Ajustes de inventario</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Kardex</div>
</Link>

</div>




</div>
</div>
        
</>

        : ""
        } 



{ user.tipo_usuario =="empresa" || user.tipo_usuario =="empleado" 
     ?<>
    <div class="menu-item has-sub">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-folder-open"></i>
</div>
<div class="menu-text">Producción</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Ordenes de producción</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Ver ordenes</div>
</Link>

</div>


</div>
</div>
        
</>

        : ""
        } 




{ user.tipo_usuario =="empresa" || user.tipo_usuario =="empleado" 
     ?<>
    <div class="menu-item has-sub">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-random"></i>
</div>
<div class="menu-text">Causaciones</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Crear causaciones</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Ver causaciones</div>
</Link>

</div>


</div>
</div>
        
</>

        : ""
        } 




{ user.tipo_usuario =="empresa" || user.tipo_usuario =="empleado" 
     ?<>
    <div class="menu-item has-sub">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-clone"></i>
</div>
<div class="menu-text">Pagos</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Comprobante de egresos</div>
</Link>

</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Recibos de caja</div>
</Link>

</div>


</div>
</div>
        
</>

        : ""
        } 




{ user.tipo_usuario =="empresa" || user.tipo_usuario =="empleado" 
     ?<>
    <div class="menu-item has-sub">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-book"></i>
</div>
<div class="menu-text">Informes</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Libro auxiliar</div>
</Link>
</div>

<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Movimientos</div>
</Link>
</div>


<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Libros oficiales</div>
</Link>

</div>


<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Financieros</div>
</Link>

</div>


<div class="menu-item active">
<Link to="/" className="menu-link">
<div class="menu-text"> <i class="fa fa-check-square" style={{color:"#FFC886"}}></i> &nbsp;Informes fiscales</div>
</Link>

</div>


</div>
</div>
        
</>

        : ""
        } 



{/*

    <div class="menu-item has-sub active">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fas fa-building"></i>
</div>
<div class="menu-text">Cargo, Perfil y Costo</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Cargos" className="menu-link">
<div class="menu-text"> <i class="fas fa-user-tie" style={{color:"#B2B2B2"}}></i>&nbsp; Mis Cargos</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Editar_cargos" className="menu-link">
<div class="menu-text"> <i class="fas fa-address-card" style={{color:"#B2B2B2"}}></i> &nbsp;Eliminar Cargos</div>
</Link>
</div>

<div class="menu-item active">
<Link to="/Perfil_cargos" className="menu-link">
<div class="menu-text"> <i class="fas fa-archive" style={{color:"#B2B2B2"}}></i> &nbsp;Perfil Cargos</div>
</Link>
</div>

<div class="menu-item active">
<Link to="/Calcular_costos" className="menu-link">
<div class="menu-text"> <i class="fas fa-calculator" style={{color:"#B2B2B2"}}></i> &nbsp;Calcular Costos</div>
</Link>
</div>


</div>
</div>
        
   
      

    <div class="menu-item has-sub active">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fas fa-users"></i>
</div>
<div class="menu-text">Empleados</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Crear_empleados" className="menu-link">
<div class="menu-text"> <i class="fas fa-user" style={{color:"#B2B2B2"}}></i>&nbsp; Crear Empleado</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Hojas_de_vida" className="menu-link">
<div class="menu-text"> <i class="fas fa-id-card-alt" style={{color:"#B2B2B2"}}></i> &nbsp;Hojas de vida</div>
</Link>
</div>

<div class="menu-item active">
<Link to="/List_documentos" className="menu-link">
<div class="menu-text"> <i class="fas fa-check-circle" style={{color:"#B2B2B2"}}></i> &nbsp;Check list documentos</div>
</Link>
</div>

<div class="menu-item active">
<Link to="/Pruebas" className="menu-link">
<div class="menu-text"> <i class="fas fa-file-archive" style={{color:"#B2B2B2"}}></i> &nbsp;Pruebas</div>
</Link>
</div>


<div class="menu-item active">
<Link to="/Visitas_domiciliarias" className="menu-link">
<div class="menu-text"> <i class="fas fa-book-reader" style={{color:"#B2B2B2"}}></i> &nbsp;Visitas domiciliarias</div>
</Link>
</div>


<div class="menu-item active">
<Link to="/Examenes_ingreso" className="menu-link">
<div class="menu-text"> <i class="fas fa-atlas" style={{color:"#B2B2B2"}}></i> &nbsp;Exámenes de ingreso</div>
</Link>
</div>




</div>
</div>
        
   


    <div class="menu-item has-sub active">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fa fa-address-book"></i>
</div>
<div class="menu-text">Contratos</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Termino_fijo" className="menu-link">
<div class="menu-text"> <i class="fa fa-address-book" style={{color:"#B2B2B2"}}></i>&nbsp; A término fijo</div>
</Link>
</div>
<div class="menu-item active">
<Link to="/Hojas_de_vida" className="menu-link">
<div class="menu-text"> <i class="fa fa-address-book" style={{color:"#B2B2B2"}}></i> &nbsp;De término indefinido</div>
</Link>
</div>

<div class="menu-item active">
<Link to="/List_documentos" className="menu-link">
<div class="menu-text"> <i class="fa fa-address-book" style={{color:"#B2B2B2"}}></i> &nbsp;Por obra o labor contratada</div>
</Link>
</div>



</div>
</div>
        
   
    

    <div class="menu-item has-sub active">
<a href="javascript:;" class="menu-link">
<div class="menu-icon">
<i class="fas fa-users"></i>
</div>
<div class="menu-text">Empleados</div>
<div class="menu-caret"></div>
</a>
<div class="menu-submenu">
<div class="menu-item active">
<Link to="/Mi_hoja_de_vida" className="menu-link">
<div class="menu-text"> <i class="fas fa-user" style={{color:"#B2B2B2"}}></i>&nbsp; Mi hoja de vida</div>
</Link>
</div>





</div>
</div>
        
   
     


<div class="menu-item">
    <Link to="/Firma" className="menu-link">
    <div class="menu-icon">
    <i class="fab fa-simplybuilt" style={{color:"white"}}></i>
    </div>
    <div class="menu-text">Crear empleado </div>
    </Link>
    </div>
        
*/}  
       
    
    
    
    <div className="menu-item d-flex">
    <a href="javascript:;" className="app-sidebar-minify-btn ms-auto" data-toggle="app-sidebar-minify"><i className="fa fa-angle-double-left"></i></a>
    </div>
    
    </div>
    </div>
    </div>

<div className="app-sidebar-bg"></div>
<div className="app-sidebar-mobile-backdrop"><a href="#" data-dismiss="app-sidebar-mobile" className="stretched-link"></a></div>

</>

  
       
    )

    
  }

 


export default Menu