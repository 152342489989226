import './index.css';
import {HashRouter as Router, Routes, Route} from "react-router-dom";

import MyContextProvider from './components/login/MyContext';
import history from './components/login/history';

import Inicio from './components/views/inicio/Inicio';

import Demo from './components/views/gemcontsoft/Demo';
import Publicidad from './components/views/gemcontsoft/Publicidad';
import Crear_publicidad from './components/views/gemcontsoft/Crear_publicidad';
import Empresas from './components/views/gemcontsoft/Empresas';
import Usuarios from './components/views/empresas/Usuarios';

import Centros_costos from './components/views/configuracion/Centros_costos';

function App() {
  return (
    <MyContextProvider>
    <Router>
    <Inicio/>
    <Routes>

    <Route history={history}></Route>
    <Route path="/Demo" element = {<Demo/>}></Route>
    <Route path="/Crear_publicidad" element = {<Crear_publicidad/>}></Route>
    <Route path="/Empresas" element = {<Empresas/>}></Route>
    <Route path="/Usuarios" element = {<Usuarios/>}></Route>
    <Route path="/Centros_costos" element = {<Centros_costos/>}></Route>

    <Route path="/" element = {<Publicidad/>}></Route>

    </Routes>
    </Router>
    </MyContextProvider>
  );
}

export default App;
