import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext'
import {Link} from 'react-router-dom';


import { AddBox, ArrowDownward } from "@material-ui/icons";
import MaterialTable from "material-table";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


//import Row from "./Row";

const multiregistros = {
  documento: "",
  name: "",
  cargo: "",
  clave: "",
  id_empresa_usuario: "",
  nombre_empresa: "",
  documento_empresa: ""
};

function Demo () {
  /*const {rootState,logoutUser} = useContext(MyContext);
  const {isAuth,user,showLogin} = rootState;*/

  const {user} = useContext(MyContext); 

 /*const {toggleNav,registerUser} = useContext(MyContext);*/
  
  const baseUrlAd="https://gemcontsoft.com/apiGemcontsoft/demo/demo.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    documento:'',
    name:'',
    cargo:'',
    clave:'',
    id_empresa_usuario:'',
    nombre_empresa:'',
    documento_empresa:'',
  });


  

  
  const [idArticulos, setIdArticulos] = useState(-1);

   
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(`https://gemcontsoft.com/apiGemcontsoft/demo/demo.php`)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }


   const peticionPut = async() =>{
    var f = new FormData();
    f.append("estado", frameworkSeleccionado.estado);
    f.append("METHOD", "PUT");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.estado= frameworkSeleccionado.estado;
       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }



  const peticionPost = async() =>{
    var f = new FormData();
    f.append("documento", frameworkSeleccionado.documento);
    f.append("name", frameworkSeleccionado.name);
    f.append("cargo", frameworkSeleccionado.cargo);
    f.append("id_empresa_usuario", user.id);
    f.append("nombre_empresa", user.name);
    f.append("documento_empresa", user.documento);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }



  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "PUT_suspender");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })


  }



  

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    (caso==="Editar")?
    abrirCerrarModalEditar():
    abrirCerrarModalEliminar()


  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },
    
    {
      title:"NOMBRES COMPLETOS",
      field: "nombres"
    },

    {
        title:"EMAIL",
        field: "correo"
      },

    {
      title:'TELÉFONO',
      field:'telefono'
    },
      
      {
        title:'CEDULA / NIT',
        field:'documento'
      },


      {
        title:'SOLICITUD',
        field:'fecha'
      },


      
      {
        title:'ESTADO',
        field:'estado',
       
       
    },
    
    
  ];


  
    
    if(user && user.tipo_usuario =="admin")

    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="row">




</div>



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">{user.name}</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<><button className="btn btn-primary"> <i class="fas fa-user-lock fa-2x"></i>  </button></> }
backIconButtonText='Página anterior'
pagination
fixedHeader


actions={[

    {
        icon : 'update',
        tooltip: 'Cambiar estado',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
 
     
              /*{
                  icon: 'block',
                  tooltip: 'suspender',
                  onClick: (event, framework) => seleccionarFramework(framework, "Eliminar")
              },*/

             
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar'
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea inhabilitar al usuario {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>





<Modal isOpen={modalEditar}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">CAMBIAR ESTADO</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

 
  
  
            <div class="col-6">
            <br/>
  <label>Estado actual</label>
  <br/>
  <input type="text" className="form-control"  onChange={handleChange}  
  value={frameworkSeleccionado && frameworkSeleccionado.estado} readOnly/>
  
  </div>


  <div className="col-6">
    
   <br/>
                 <label>Cambiar cargo:</label>
              <select className="form-select" onChange={handleChange} name="estado">
                   <option value="">--Seleccionar estado--</option>
                   <option value="En revisión">En revisión</option>
                   <option value="Aceptado">Aceptado</option>
                   <option value="Cancelado">Cancelado</option>
                  

                              
                 </select>
               
            </div> 
  
    
  
    

   </div>
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary " onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Cambiar estado</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">USUARIO</font></ModalHeader>
 <ModalBody>
   <div className="form-group">
   <div className="row">

   <div class="col-7">

<label>Nombre completos:</label>
<br/>
      <input type="text" className="form-control"  name="name" onChange={handleChange}
        />
  </div>

<div class="col-5">

<label>Documento:</label>
<br/>
      <input type="text" className="form-control"  name="documento" onChange={handleChange}
        />
  </div>

  <div class="col-6">
  <br/>
  <label>Contraseña:</label>
  <br/>
  <input type="text"  className="form-control" required minLength={8} maxLength={15}  name="clave" onChange={handleChange} 
   />
   <label>Mínimo 8 caracteres</label>
  <br/>
  </div>



  
  <div style={{display:"none"}}>
  <input name="id_empresa_usuario"  className="form-control" type="text" placeholder="Id empresa" value={user.id} autoComplete="off" readOnly />
  <input name="nombre_empresa"  className="form-control" type="text" placeholder="Nombre emperesa" value={user.name} autoComplete="off" readOnly />
  <input name="documento_empresa"  className="form-control" type="text" placeholder="Documento emperesa" value={user.documento} autoComplete="off" readOnly />
  

  
  </div>
  
 

  </div>

   
   </div>
 </ModalBody>
 <ModalFooter>
   <button className="btn btn-primary" onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square"></i> Guardar</button>{" "}
   <button className="btn btn-danger" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(!user){
    return"";
}

}

export default Demo
