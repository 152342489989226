import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext'
import {Link} from 'react-router-dom';


import axios from 'axios';
import Login from '../../login/Login';



//import { Fade } from 'react-slideshow-image';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'



function Publicidad () {
  const {user} = useContext(MyContext); 


  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded' || e.message === 'Script error.') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        )
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        )
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    })
  }, [])


  
  const baseUrlAd="https://gemcontsoft.com/apiGemcontsoft/demo/demo.php"
  const [data, setData]=useState([]);
 

  /*const peticionGet =async() =>{
    await axios.get(`https://gemcontsoft.com/apiGemcontsoft/demo/demo.php`)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }*/







 /*  const images = [
    "https://images.unsplash.com/photo-1509721434272-b79147e0e708?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
    "https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/negocios.jpg",
    "https://images.unsplash.com/photo-1536987333706-fc9adfb10d91?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1500&q=80",
];*/


const [ruta, setRuta1] = useState([]);
       
    const peticionGet_imagenes1 =async() =>{
           
    await axios.get(`https://gemcontsoft.com/apiGemcontsoft/p_img/index1.php?id='1'`)
    .then((res) => {
    
     setRuta1(res.data.ruta);
       
    }).catch(error=>{
      console.log(error);
    })
  
    
  }



  const [ruta2, setRuta2] = useState([]);
       
    const peticionGet_imagenes2 =async() =>{
           
    await axios.get(`https://gemcontsoft.com/apiGemcontsoft/p_img/index2.php?id='2'`)
    .then((res) => {
    
     setRuta2(res.data.ruta2);
       
    }).catch(error=>{
      console.log(error);
    })
  
    
  }



  const [ruta3, setRuta3] = useState([]);
       
    const peticionGet_imagenes3 =async() =>{
           
    await axios.get(`https://gemcontsoft.com/apiGemcontsoft/p_img/index3.php?id='3'`)
    .then((res) => {
    
     setRuta3(res.data.ruta3);
       
    }).catch(error=>{
      console.log(error);
    })
  
    
  }


  const [ruta4, setRuta4] = useState([]);
       
  const peticionGet_imagenes4 =async() =>{
         
  await axios.get(`https://gemcontsoft.com/apiGemcontsoft/p_img/index4.php?id='4'`)
  .then((res) => {
  
   setRuta4(res.data.ruta4);
     
  }).catch(error=>{
    console.log(error);
  })

  
}



const [ruta5, setRuta5] = useState([]);
       
const peticionGet_imagenes5 =async() =>{
       
await axios.get(`https://gemcontsoft.com/apiGemcontsoft/p_img/index5.php?id='5'`)
.then((res) => {

 setRuta5(res.data.ruta5);
   
}).catch(error=>{
  console.log(error);
})


}




const [link1, setLink1] = useState([]);
       
const peticionGet_link1 =async() =>{
       
await axios.get(`https://gemcontsoft.com/apiGemcontsoft/p_img/link1.php?id='1'`)
.then((res) => {

 setLink1(res.data.link1);
   
}).catch(error=>{
  console.log(error);
})


}


const [link2, setLink2] = useState([]);
       
const peticionGet_link2 =async() =>{
       
await axios.get(`https://gemcontsoft.com/apiGemcontsoft/p_img/link2.php?id='2'`)
.then((res) => {

 setLink2(res.data.link2);
   
}).catch(error=>{
  console.log(error);
})


}



const [link3, setLink3] = useState([]);
       
const peticionGet_link3 =async() =>{
       
await axios.get(`https://gemcontsoft.com/apiGemcontsoft/p_img/link3.php?id='3'`)
.then((res) => {

 setLink3(res.data.link3);
   
}).catch(error=>{
  console.log(error);
})


}



const [link4, setLink4] = useState([]);
       
const peticionGet_link4 =async() =>{
       
await axios.get(`https://gemcontsoft.com/apiGemcontsoft/p_img/link4.php?id='4'`)
.then((res) => {

 setLink4(res.data.link4);
   
}).catch(error=>{
  console.log(error);
})


}




const [link5, setLink5] = useState([]);
       
const peticionGet_link5 =async() =>{
       
await axios.get(`https://gemcontsoft.com/apiGemcontsoft/p_img/link5.php?id='5'`)
.then((res) => {

 setLink5(res.data.link5);
   
}).catch(error=>{
  console.log(error);
})


}





  
 useEffect(()=>{
    

    const interval = setInterval(() => {
      
      peticionGet_imagenes1();
      peticionGet_imagenes2();
      peticionGet_imagenes3();
      peticionGet_imagenes4();
      peticionGet_imagenes5();
      peticionGet_link1();
      peticionGet_link2();
      peticionGet_link3();
      peticionGet_link4();
      peticionGet_link5();
       
     }, 1000);
 
   return () => setInterval(interval);
 
   },[])

  
   const [shouldShow, setShouldShow] = useState(false);
    
    if(user)

    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="row">






</div>



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">{user.name}</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<div className="slide-container" style={{width: '100%'}}>


{ ruta !="" && ruta2 !="" && ruta3 !="" && ruta4 !="" && ruta5 !=""

?   
<Slide>


            <div className="each-slide-effect">
                 <a href={`${link1}`}>
              <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta}`} />
               
                </a>
           
            </div>

            

            <div className="each-slide-effect">
            <a href={`${link2}`}>
            <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta2}`} />
            </a>
                   
            </div>

            <div className="each-slide-effect">
            <a href={`${link3}`}>
            <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta3}`} />                 
              </a>
            </div>

            <div className="each-slide-effect">
            <a href={`${link4}`}>
            <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta4}`} />                 
              </a>
            </div>


            <div className="each-slide-effect">
            <a href={`${link5}`}>
            <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta5}`} />                 
              </a>
            </div>
        </Slide>

: ""
}   



{ ruta =="" && ruta2 =="" && ruta3 =="" && ruta4 =="" && ruta5 ==""

?   
<Slide>
             
            <div className="each-slide-effect">
                 <a href={`${link1}`}>
              <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta}`} />
               
                </a>
           
            </div>


            
         
        </Slide>

: ""
}  



{ ruta !="" && ruta2 !="" && ruta3 =="" && ruta4 =="" && ruta5 ==""

?   
<Slide>
             
            <div className="each-slide-effect">
                 <a href={`${link1}`}>
              <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta}`} />
               
                </a>
           
            </div>


            <div className="each-slide-effect">
            <a href={`${link2}`}>
            <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta2}`} />
            </a>
                   
            </div>
         
        </Slide>

: ""
}  


{ ruta !="" && ruta2 !="" && ruta3 !="" && ruta4 =="" && ruta5 ==""

?   
<Slide>
             
            <div className="each-slide-effect">
                 <a href={`${link1}`}>
              <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta}`} />
               
                </a>
           
            </div>


            <div className="each-slide-effect">
            <a href={`${link2}`}>
            <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta2}`} />
            </a>
                   
            </div>


            <div className="each-slide-effect">
            <a href={`${link3}`}>
            <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta3}`} />                 
              </a>
            </div>
         
        </Slide>

: ""
} 


{ ruta !="" && ruta2 !="" && ruta3 !="" && ruta4 !="" && ruta5 ==""

?   
<Slide>
             
            <div className="each-slide-effect">
                 <a href={`${link1}`}>
              <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta}`} />
               
                </a>
           
            </div>


            <div className="each-slide-effect">
            <a href={`${link2}`}>
            <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta2}`} />
            </a>
                   
            </div>


            <div className="each-slide-effect">
            <a href={`${link3}`}>
            <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta3}`} />                 
              </a>
            </div>

            <div className="each-slide-effect">
            <a href={`${link4}`}>
            <img style={{ width: '100%' }} src={ `https://gemcontsoft.com/apiGemcontsoft/fotos_p/Imagenes/${ruta4}`} />                 
              </a>
            </div>
         
        </Slide>

: ""
}  













    </div>




</div>






</div>

</div>




</div>



</div>
   
  )
}

else if(!user){
    return"";
}

}

export default Publicidad
