import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext'
import {Link} from 'react-router-dom';


import { AddBox, ArrowDownward } from "@material-ui/icons";
//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


function Centros_costos () {
    const {user} = useContext(MyContext); 

//tipo de empresa
const [value2, setValue2] = React.useState()

//select tdoc
const[tdoc,  seTdoc] = useState('');

const handleChangetdoc=e=>{

    seTdoc(e.target.value);
    const {name, value}=e.target;
   
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   // console.log(frameworkSeleccionado);
   }
  
  const baseUrlAd="https://gemcontsoft.com/apiGemcontsoft/configuracion/centros_costos.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [modalHabilitar, setModalEliminarHabilitar]=useState(false);
  const [modalOk, setModalOk]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    documento:'',
    name:'',
    clave:'',
    id_empresa_usuario:'',
    nombre_empresa:'',
    documento_empresa:'',
  });

 
  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(`https://gemcontsoft.com/apiGemcontsoft/configuracion/centros_costos.php`)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }


   const peticionPut = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "PUT");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.name= frameworkSeleccionado.name;
         framework.documento= frameworkSeleccionado.documento;
        framework.clave = frameworkSeleccionado.clave;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }



  const peticionPost = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
   /* f.append("id_empresa_usuario", user.id);
    f.append("nombre_empresa", user.name);
    f.append("documento_empresa", user.documento);
    f.append("clave", frameworkSeleccionado.clave);*/
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
     abrirCerrarModalOk();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }

  const abrirCerrarModalEliminarHabilitar=()=>{
    setModalEliminarHabilitar(!modalHabilitar);
  }

  const abrirCerrarModalOk=()=>{
    setModalOk(!modalOk);
  }




  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "PUT_suspender");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })


  }


  const peticionDeleteHabilitar = async() =>{
    var f = new FormData();
    f.append("METHOD", "PUT_habilitar");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminarHabilitar();
    }).catch(error=>{
      console.log(error);
    })


  }


/*
  const [cargo, setCountry]= useState([]);

  useEffect( ()=>{

    const interval = setInterval(() => {
    const getcountry= async ()=>{
   const req= await fetch(`https://gemcontsoft.hoztcrea.com/apiAlira/empresas_usuarios/cargar_cargos.php?usuarioE=${user.id}`)
  // const req= await fetch("https://parqueoenvia.co/apiParqueoenvia/zonas/country.php");
      const getres= await req.json();
      console.log(getres);
      setCountry(await getres);
  
    }
    getcountry();

}, 2000);

return () => setInterval(interval);
  
  
   },[]);

  */

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    
    if(caso==="Editar"){
        abrirCerrarModalEditar();
        }

    if(caso==="Eliminar"){
        abrirCerrarModalEliminar();
            }


    if(caso==="Habilitar"){
    abrirCerrarModalEliminarHabilitar();
    }




  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },

    {
        title:"NOMBRE",
        field: "name"
      },
    
              
      {
        title:'ESTADO',
        field:'estado',
       
       // render: rowData => <img src={rowData.url} style={{width: 50, borderRadius: '50%'}}/>   
    },
    
    
  ];


  
    
  if(user)
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="row">




{/*<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-red">
<div class="stats-icon"><i class="fa fa-clock"></i></div>
<div class="stats-info">
<h4>PUNTOS SUSPENDIDOS</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_suspendidas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
  </div> */}

</div>



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">{user.name}</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<><button className="btn btn-primary" onClick={()=>abrirCerrarModalInsertar()}> <i class="fa fa-sitemap fa-2x"></i>  AGREGAR CENTRO DE COSTO</button></> }
backIconButtonText='Página anterior'
pagination
fixedHeader


actions={[

    {
        icon : 'edit',
        tooltip: 'Editar',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
 
     
              {
                  icon: 'block',
                  tooltip: 'Suspender',
                  onClick: (event, framework) => seleccionarFramework(framework, "Eliminar")
              },

              {
                icon: 'badge',
                tooltip: 'Habilitar',
                onClick: (event, framework) => seleccionarFramework(framework, "Habilitar")
            },

             
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar'
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


{/*<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea inhabilitar al usuario {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>*/}


<Modal isOpen={modalEliminar}>
 <ModalBody>
 <h6> ¿Estas seguro que desea inhabilitar el centro de costo {frameworkSeleccionado && frameworkSeleccionado.name}?</h6>
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger btn-lg"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary btn-lg"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
   </Modal>


   <Modal isOpen={modalHabilitar}>
 <ModalBody>
 <h6> ¿Estas seguro que desea habilitar el centro de costo {frameworkSeleccionado && frameworkSeleccionado.name}?</h6>
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger btn-lg"
     onClick={()=>peticionDeleteHabilitar()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary btn-lg"
       onClick={()=>abrirCerrarModalEliminarHabilitar()}
       >
         NO
     </button>

   </ModalFooter>
   </Modal>


<Modal isOpen={modalOk}>
 <ModalBody>
  <center> <h6>REGISTRO PROCESADO CORRECTAMENTE <br/><br/> {frameworkSeleccionado && frameworkSeleccionado.name}</h6></center>
   <br/>
   <center><img src="../assets/img/logo/procesar.png" alt="" /></center>
   </ModalBody>
   <ModalFooter style={{alignSelf:"center"}}>
     <button className="btn btn-danger btn-lg"
     onClick={()=>abrirCerrarModalOk()}
     >
       SALIR
     </button>
     

   </ModalFooter>
 

</Modal>




<Modal isOpen={modalEditar} style={{maxWidth:"800px"}}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">EDITAR CENTRO DE COSTO</font></ModalHeader>
 <ModalBody>
 <div className="form-group">



<div className="row">


<div class="col-12">

<label>Nombre:</label>
<br/>
      <input type="text" className="form-control"  name="name" onChange={handleChange}
       value={frameworkSeleccionado && frameworkSeleccionado.name}   />
  </div>

  

  </div>


</div>
 </ModalBody>
 <ModalFooter style={{justifyContent:"center"}}>
   <button className="btn btn-primary btn-lg" onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Editar</button>{" "}
   <button className="btn btn-danger btn-lg" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar} style={{maxWidth:"800px"}}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">AGREGAR CENTRO DE COSTO</font></ModalHeader>
 <ModalBody>
   <div className="form-group">

  

   <div className="row">


   <div class="col-12">

<label>Nombre:</label>
<br/>
      <input type="text" className="form-control"  name="name" onChange={handleChange}
        />
  </div>



  
  </div>

   
   </div>
 </ModalBody>
 <ModalFooter style={{justifyContent:"center"}}>
   
   <button className="btn btn-primary btn-lg"  onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square "></i> Guardar</button>{" "}
   <button className="btn btn-danger btn-lg" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
  
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(!user){
    return"";
}

}

export default Centros_costos
