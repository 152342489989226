import React, {useContext,useState,useEffect,forwardRef} from 'react';
import {MyContext} from '../../login/MyContext'
import {Link} from 'react-router-dom';


import { AddBox, ArrowDownward } from "@material-ui/icons";
//import MaterialTable from "material-table";
import MaterialTable from '@material-table/core';
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import axios from 'axios';
import Login from '../../login/Login';


function Empresas () {
    const {user} = useContext(MyContext); 

//tipo de empresa
const [value2, setValue2] = React.useState()

//select tdoc
const[tdoc,  seTdoc] = useState('');

const handleChangetdoc=e=>{

    seTdoc(e.target.value);
    const {name, value}=e.target;
   
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   // console.log(frameworkSeleccionado);
   }
  
  const baseUrlAd="https://gemcontsoft.com/apiGemcontsoft/empresas/empresas.php"
  const [data, setData]=useState([]);
  const [modalInsertar, setModalInsertar]=useState(false);
  const [modalEditar, setModalEditar]=useState(false);
  const [modalEliminar, setModalEliminar]=useState(false);
  const [modalHabilitar, setModalEliminarHabilitar]=useState(false);
  const [modalOk, setModalOk]=useState(false);
  const [frameworkSeleccionado, setFrameworkSeleccionado]=useState({
    id:'',
    documento:'',
    name:'',
    clave:'',
    id_empresa_usuario:'',
    nombre_empresa:'',
    documento_empresa:'',
  });

 
  
  const handleChange=e=>{
    const {name, value}=e.target;
    setFrameworkSeleccionado((prevState)=>({
      ...prevState,
      [name]: value
    }))
   console.log(frameworkSeleccionado);
  }

  const abrirCerrarModalInsertar=()=>{
    setModalInsertar(!modalInsertar);
  }

  const peticionGet =async() =>{
    await axios.get(`https://gemcontsoft.com/apiGemcontsoft/empresas/empresas.php`)
    .then(response=>{
     setData(response.data);
    }).catch(error=>{
      console.log(error);
    })

  }


   const peticionPut = async() =>{
    var f = new FormData();
    f.append("name", frameworkSeleccionado.name);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("METHOD", "PUT");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
         framework.name= frameworkSeleccionado.name;
         framework.documento= frameworkSeleccionado.documento;
        framework.clave = frameworkSeleccionado.clave;

       }
     });
     setData(dataNueva);
     abrirCerrarModalEditar();
    }).catch(error=>{
      console.log(error);
    })

  }



  const peticionPost = async() =>{
    var f = new FormData();
    f.append("tdoc", frameworkSeleccionado.tdoc);
    f.append("documento", frameworkSeleccionado.documento);
    f.append("clave", frameworkSeleccionado.clave);
    f.append("licencia", frameworkSeleccionado.licencia);
    f.append("name", frameworkSeleccionado.name);
    f.append("celular", frameworkSeleccionado.celular);
    f.append("celular_alternativo", frameworkSeleccionado.celular_alternativo);
    f.append("email", frameworkSeleccionado.email);
    f.append("direccion", frameworkSeleccionado.direccion);
    f.append("departamento", frameworkSeleccionado.departamento);
    f.append("ciudad", frameworkSeleccionado.ciudad);
    f.append("web", frameworkSeleccionado.web);
    f.append("nombre_r", frameworkSeleccionado.nombre_r);
    f.append("tdoc_r", frameworkSeleccionado.tdoc_r);
    f.append("documento_r", frameworkSeleccionado.documento_r);
    f.append("celular_r", frameworkSeleccionado.celular_r);
    f.append("email_r", frameworkSeleccionado.email_r);
    f.append("direccion_r", frameworkSeleccionado.direccion_r);
    
   /* f.append("id_empresa_usuario", user.id);
    f.append("nombre_empresa", user.name);
    f.append("documento_empresa", user.documento);
    f.append("clave", frameworkSeleccionado.clave);*/
    f.append("METHOD", "POST");
    await axios.post(baseUrlAd,f)
    .then(response=>{
     setData(data.concat(response.data));
     abrirCerrarModalInsertar();
     abrirCerrarModalOk();
    }).catch(error=>{
      console.log(error);
    })

  }

  const abrirCerrarModalEditar=()=>{
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar=()=>{
    setModalEliminar(!modalEliminar);
  }

  const abrirCerrarModalEliminarHabilitar=()=>{
    setModalEliminarHabilitar(!modalHabilitar);
  }

  const abrirCerrarModalOk=()=>{
    setModalOk(!modalOk);
  }




  const peticionDelete = async() =>{
    var f = new FormData();
    f.append("METHOD", "PUT_suspender");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminar();
    }).catch(error=>{
      console.log(error);
    })


  }


  const peticionDeleteHabilitar = async() =>{
    var f = new FormData();
    f.append("METHOD", "PUT_habilitar");
    await axios.post(baseUrlAd,f, {params: {id: frameworkSeleccionado.id}})
    .then(response=>{
     var dataNueva = data;
     dataNueva.map(framework=>{
       if(framework.id===frameworkSeleccionado.id){
       }
     });
     setData(dataNueva);
     abrirCerrarModalEliminarHabilitar();
    }).catch(error=>{
      console.log(error);
    })


  }


/*
  const [cargo, setCountry]= useState([]);

  useEffect( ()=>{

    const interval = setInterval(() => {
    const getcountry= async ()=>{
   const req= await fetch(`https://gemcontsoft.hoztcrea.com/apiAlira/empresas_usuarios/cargar_cargos.php?usuarioE=${user.id}`)
  // const req= await fetch("https://parqueoenvia.co/apiParqueoenvia/zonas/country.php");
      const getres= await req.json();
      console.log(getres);
      setCountry(await getres);
  
    }
    getcountry();

}, 2000);

return () => setInterval(interval);
  
  
   },[]);

  */

  const seleccionarFramework=(framework, caso)=>{
    setFrameworkSeleccionado(framework);
    
    if(caso==="Editar"){
        abrirCerrarModalEditar();
        }

    if(caso==="Eliminar"){
        abrirCerrarModalEliminar();
            }


    if(caso==="Habilitar"){
    abrirCerrarModalEliminarHabilitar();
    }




  }

  useEffect(()=>{
    

    const interval = setInterval(() => {
      
       peticionGet();
       
     }, 2000);
 
   return () => setInterval(interval);
 
   },[])

  

  
  const columnas =[
    {
      title:"ID",
      field: "id",
      
    },

    {
        title:"RAZÓN SOCIAL",
        field: "name"
      },
    
    {
      title:"DOCUMENTO",
      field: "documento"
    },

    
    {
      title:'CELULAR',
      field:'celular'
    },
      
      {
        title:'TIPO USUARIO',
        field:'tipo_usuario'
      },


      {
        title:'LICENCIAS',
        field:'licencia'
      },

      
      {
        title:'ESTADO',
        field:'suspender',
       
       // render: rowData => <img src={rowData.url} style={{width: 50, borderRadius: '50%'}}/>   
    },
    
    
  ];


  
    
  if(user && user.tipo_usuario =="admin")
    {
  return (
 
<div id="content" class="app-content">

<ol class="breadcrumb float-xl-end">


</ol>


<div class="row">




{/*<div class="col-xl-3 col-md-6">
<div class="widget widget-stats bg-red">
<div class="stats-icon"><i class="fa fa-clock"></i></div>
<div class="stats-info">
<h4>PUNTOS SUSPENDIDOS</h4>
<p>0</p>
</div>
<div class="stats-link">
<Link  to="/Empresas_suspendidas" >Ver Módulo <i class="fa fa-arrow-alt-circle-right"></i></Link>
</div>
</div>
  </div> */}

</div>



<div class="row">

<div class="col-xl-12">


<div class="panel panel-inverse" data-sortable-id="form-stuff-1" data-init="true">

<div class="panel-heading ui-sortable-handle">
<h4 class="panel-title">{user.name}</h4>
<div class="panel-heading-btn">
<a href="javascript:;" class="btn btn-xs btn-icon btn-default" data-toggle="panel-expand" title="Expandir"><i class="fa fa-expand"></i></a>

</div>
</div>


<div class="panel-body">



<MaterialTable

     
columns={columnas}
data={data}
title= {<><button className="btn btn-primary" onClick={()=>abrirCerrarModalInsertar()}> <i class="fas fa-user-shield fa-2x"></i>  AGREGAR EMPRESA</button></> }
backIconButtonText='Página anterior'
pagination
fixedHeader


actions={[

    {
        icon : 'edit',
        tooltip: 'Editar',
        onClick: (event, framework)=>seleccionarFramework(framework, "Editar")
      },
 
     
              {
                  icon: 'block',
                  tooltip: 'Suspender',
                  onClick: (event, framework) => seleccionarFramework(framework, "Eliminar")
              },

              {
                icon: 'badge',
                tooltip: 'Habilitar',
                onClick: (event, framework) => seleccionarFramework(framework, "Habilitar")
            },

             
]}
/*options={{
  actionsColumnIndex: -1
}}*/
options={{
    
    actionsColumnIndex: -1,
 actionsCellStyle: {
   // backgroundColor: "#ffccdd",
   // color: "#FF00dd"
  
 },

 headerStyle: { backgroundColor: "#348fe2",  headerStyle: { position: 'sticky'}, boxShadow:"0 0  0 2px rgb(255,255,255)", color: "white", padding: "0.75rem 0.9375rem", fontSize: 10}
}}
localization={{
    header:{
    actions: 'ACCIONES'
    
  },
  body:{ 
    emptyDataSourceMessage:'No hay registros que mostrar'
    
   },

 

   pagination: {
     firstAriaLabel: 'Primera página',
     firstTooltip: 'Primera página',
     labelDisplayedRows: '{from}-{to} de {count}',
     labelRowsPerPage: 'Filas por página:',
     labelRowsSelect: 'filas',
     lastAriaLabel: 'Ultima página',
     lastTooltip: 'Ultima página',
     nextAriaLabel: 'Pagina siguiente',
     nextTooltip: 'Pagina siguiente',
     previousAriaLabel: 'Pagina anterior',
     previousTooltip: 'Pagina anterior',
   },
   toolbar: {
     searchPlaceholder: 'Buscar',
     searchTooltip: 'Buscar',
     showColumnsAriaLabel: 'Mostrar columnas',
     showColumnsTitle: 'Mostrar columnas',
   },

   
}}



/>


<form >
    
</form>
</div>






</div>

</div>




</div>


{/*<Modal isOpen={modalEliminar}>
 <ModalBody>
   ¿Estas seguro que desea inhabilitar al usuario {frameworkSeleccionado && frameworkSeleccionado.name}?
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
 

</Modal>*/}


<Modal isOpen={modalEliminar}>
 <ModalBody>
 <h6> ¿Estas seguro que desea inhabilitar la empresa {frameworkSeleccionado && frameworkSeleccionado.name}?</h6>
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger btn-lg"
     onClick={()=>peticionDelete()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary btn-lg"
       onClick={()=>abrirCerrarModalEliminar()}
       >
         NO
     </button>

   </ModalFooter>
   </Modal>


   <Modal isOpen={modalHabilitar}>
 <ModalBody>
 <h6> ¿Estas seguro que desea habilitar la empresa {frameworkSeleccionado && frameworkSeleccionado.name}?</h6>
   </ModalBody>
   <ModalFooter>
     <button className="btn btn-danger btn-lg"
     onClick={()=>peticionDeleteHabilitar()}
     >
       SI
     </button>
     <button
       className="btn btn-secondary btn-lg"
       onClick={()=>abrirCerrarModalEliminarHabilitar()}
       >
         NO
     </button>

   </ModalFooter>
   </Modal>


<Modal isOpen={modalOk}>
 <ModalBody>
  <center> <h6>REGISTRO PROCESADO CORRECTAMENTE <br/><br/> {frameworkSeleccionado && frameworkSeleccionado.name}</h6></center>
   <br/>
   <center><img src="../assets/img/logo/procesar.png" alt="" /></center>
   </ModalBody>
   <ModalFooter style={{alignSelf:"center"}}>
     <button className="btn btn-danger btn-lg"
     onClick={()=>abrirCerrarModalOk()}
     >
       SALIR
     </button>
     

   </ModalFooter>
 

</Modal>




<Modal isOpen={modalEditar} style={{maxWidth:"800px"}}>
 <ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">EDITAR EMPRESA</font></ModalHeader>
 <ModalBody>
 <div className="form-group">

<div className="col-12">

<center><label>-------------------------------------- TIPO DE EMPRESA --------------------------------------- </label> </center>
<br/>
</div>

<div className="row">

<center>
<div class="col-7">

<select name="edad"  value={value2}  class="form-control btn btn-info" onChange={e => setValue2(e.currentTarget.value)} >
<option value='no' selected style={{color:'yellow'}}>-- SELECCIONAR --</option>
<option value="rcomun">RÉGIMEN COMÚN</option>
<option value="rsimplificado">RÉGIMEN SIMPLIFICADO</option>
<option value="rordinario">RÉGIMEN ORDINARIO</option>


</select> 






{/*<div class="col-sm-2">
{ value2==='Restriccion de edad' ? (<div>

 <div>
     
   <h1>Restricción de edad</h1>
 </div>
</div>
):null}




</div> */}
{/*
<label>Nombre completos:</label>
<br/>
   <input type="text" className="form-control"  name="name" onChange={handleChange}
/> */}
</div>

</center>


<div className="col-12">
<br/>
<center><label>-------------------------------------- DATOS DE ACCESO --------------------------------------- </label> </center>
<br/>
</div>


{ value2==='rcomun' ? (<div class="col-5">


<label>Tipo documento:</label>
<br/>
 
<select name="tdoc" class="form-control"  onChange={handleChangetdoc} value={frameworkSeleccionado && frameworkSeleccionado.tdoc} >
<option value='no' selected>-- Seleccionar --</option>
<option value="NIT">(NIT) Número de identificación tributaria</option>



</select> 

</div>
):null}



{ value2==='rsimplificado' ? (<div class="col-5">


<label>Tipo documento:</label>
<br/>
 
<select name="tdoc" class="form-control" onChange={handleChangetdoc} value={frameworkSeleccionado && frameworkSeleccionado.tdoc} >
<option value='no' selected>-- Seleccionar --</option>
<option value="CC">(CC) Cedula de ciudadania</option>
<option value="CE">(CE) Cedula de extranjeria</option>



</select> 

</div>
):null}


{ value2==='rordinario' ? (<div class="col-5">


<label>Tipo documento:</label>
<br/>
 
<select name="tdoc" class="form-control" onChange={handleChangetdoc} value={frameworkSeleccionado && frameworkSeleccionado.tdoc}>
<option value='no' selected>-- Seleccionar --</option>
<option value="CC">(CC) Cedula de ciudadania</option>
<option value="CE">(CE) Cedula de extranjeria</option>
<option value="NIT">(NIT) Número de identificación tributaria</option>



</select> 

</div>
):null}


<div class="col-3">

<label>Documento:</label>
<br/>
   <input type="text" className="form-control"  name="documento" onChange={handleChange}
   value={frameworkSeleccionado && frameworkSeleccionado.documento}  />
</div>

<div class="col-4">

<label>Contraseña:</label>
<br/>
<input type="text"  className="form-control" required minLength={8} maxLength={15}  name="clave" onChange={handleChange} 
/>
<label>Mínimo 8 caracteres</label>
<br/>
</div>


<div class="col-2">

<label>Licencias:</label>
<br/>
   <input type="number" className="form-control"  name="licencia" onChange={handleChange}
    value={frameworkSeleccionado && frameworkSeleccionado.licencia} />
</div>

<div className="col-12">
<br/>
<center><label>-------------------------------------- INFORMACIÓN EMPRESA --------------------------------------- </label> </center>
<br/>
</div>

<div class="col-6">

<label>Razón social:</label>
<br/>
   <input type="text" className="form-control"  name="name" onChange={handleChange}
   value={frameworkSeleccionado && frameworkSeleccionado.name}  />
</div>


<div class="col-3">

<label>Teléfono:</label>
<br/>
   <input type="number" className="form-control"  name="celular" onChange={handleChange}
    value={frameworkSeleccionado && frameworkSeleccionado.celular} />
</div>


<div class="col-3">

<label>Teléfono alternativo:</label>
<br/>
   <input type="number" className="form-control"  name="celular_alternativo" onChange={handleChange}
    value={frameworkSeleccionado && frameworkSeleccionado.celular_alternativo} />
</div>


<div class="col-4">
<br />
<label>Email:</label>
<br/>
   <input type="email" className="form-control"  name="email" onChange={handleChange}
    value={frameworkSeleccionado && frameworkSeleccionado.email} />
</div>


<div class="col-8">
<br />
<label>Dirección:</label>
<br/>
   <input type="text" className="form-control"  name="direccion" onChange={handleChange}
   value={frameworkSeleccionado && frameworkSeleccionado.direccion}  />
</div>


<div class="col-4">
<br/>
<label>Departamento:</label>
<br/>
   <input type="text" className="form-control"  name="departamento" onChange={handleChange}
    value={frameworkSeleccionado && frameworkSeleccionado.departamento} />
</div>


<div class="col-4">
<br/>
<label>Municipio:</label>
<br/>
   <input type="text" className="form-control"  name="ciudad" onChange={handleChange}
    value={frameworkSeleccionado && frameworkSeleccionado.ciudad} />
</div>


<div class="col-4">
<br/>
<label>URL web:</label>
<br/>
   <input type="text" className="form-control"  name="web" onChange={handleChange}
   value={frameworkSeleccionado && frameworkSeleccionado.web}  />
</div>


{ value2==='rcomun' ? (<><div class="col-4">


<br/>
<label>Nombre del representante:</label>
<br/>
   <input type="text" className="form-control"  name="nombre_r" onChange={handleChange}
    value={frameworkSeleccionado && frameworkSeleccionado.nombre_r} />
</div>


<div class="col-4">

<br/>
<label>Tipo documento representante:</label>
<br/>

<select name="tdoc_r" class="form-control" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.tdoc_r}>
<option value='no' selected>-- Seleccionar --</option>
<option value="CC">(CC) Cedula de ciudadania</option>
<option value="CE">(CE) Cedula de extranjeria</option>



</select> 


</div>


<div class="col-4">


<br/>
<label>Documento representante:</label>
<br/>
   <input type="text" className="form-control"  name="documento_r" onChange={handleChange}
    value={frameworkSeleccionado && frameworkSeleccionado.documento_r} />
</div>


<div class="col-4">


<br/>
<label>Celular representante:</label>
<br/>
   <input type="text" className="form-control"  name="celular_r" onChange={handleChange}
    value={frameworkSeleccionado && frameworkSeleccionado.celular_r} />
</div>

<div class="col-8">


<br/>
<label>Email representante:</label>
<br/>
   <input type="email" className="form-control"  name="email_r" onChange={handleChange}
    value={frameworkSeleccionado && frameworkSeleccionado.email_r} />
</div>


<div class="col-12">


<br/>
<label>Dirección representante:</label>
<br/>
   <input type="text" className="form-control"  name="direccion_r" onChange={handleChange}
    value={frameworkSeleccionado && frameworkSeleccionado.direccion_r} />
</div>
</>
):null}


{ value2==='rsimplificado' ? (<><div class="col-4">


<br/>
<label>Nombre del representante:</label>
<br/>
 <input type="text" className="form-control"  name="nombre_r" onChange={handleChange}
  value={frameworkSeleccionado && frameworkSeleccionado.nombre_r} />
</div>



<div class="col-4">


<br/>
<label>Celular representante:</label>
<br/>
 <input type="text" className="form-control"  name="documento_r" onChange={handleChange}
  value={frameworkSeleccionado && frameworkSeleccionado.documento_r} />
</div>

<div class="col-4">
<br/>


<label>Email representante:</label>
<br/>
 <input type="email" className="form-control"  name="email_r" onChange={handleChange}
  value={frameworkSeleccionado && frameworkSeleccionado.email_r} />
</div>


<div class="col-12">


<br/>
<label>Dirección representante:</label>
<br/>
 <input type="text" className="form-control"  name="direccion_r" onChange={handleChange}
   value={frameworkSeleccionado && frameworkSeleccionado.direccion_r}/>
</div>
</>
):null}


{ value2==='rordinario' ? (<><div class="col-4">


<br/>
<label>Nombre del representante:</label>
<br/>
 <input type="text" className="form-control"  name="nombre_r" onChange={handleChange}
  value={frameworkSeleccionado && frameworkSeleccionado.nombre_r} />
</div>


<div class="col-4">

<br/>
<label>Tipo documento representante:</label>
<br/>

<select name="tdoc_r" class="form-control" onChange={handleChange} value={frameworkSeleccionado && frameworkSeleccionado.tdoc_r}>
<option value='no' selected>-- Seleccionar --</option>
<option value="CC">(CC) Cedula de ciudadania</option>
<option value="CE">(CE) Cedula de extranjeria</option>



</select> 


</div>


<div class="col-4">


<br/>
<label>Documento representante:</label>
<br/>
 <input type="text" className="form-control"  name="documento_r" onChange={handleChange}
  value={frameworkSeleccionado && frameworkSeleccionado.documento_r} />
</div>


<div class="col-4">


<br/>
<label>Celular representante:</label>
<br/>
 <input type="text" className="form-control"  name="celular_r" onChange={handleChange}
  value={frameworkSeleccionado && frameworkSeleccionado.celular_r} />
</div>

<div class="col-8">


<br/>
<label>Email representante:</label>
<br/>
 <input type="email" className="form-control"  name="email_r" onChange={handleChange}
  value={frameworkSeleccionado && frameworkSeleccionado.email_r} />
</div>


<div class="col-12">


<br/>
<label>Dirección representante:</label>
<br/>
 <input type="email" className="form-control"  name="direccion_r" onChange={handleChange}
  value={frameworkSeleccionado && frameworkSeleccionado.direccion_r} />
</div>
</>
):null}


{/*<div className="col-6">
 
<br/>
              <label>Agregar cargo:</label>
           <select name="cargo" className="form-select" onChange={handleChange}>
                <option>--Seleccionar cargo--</option>
                {
                  cargo.map( (getcon)=>(
                   
                <option key={getcon.id} value={getcon.cargo}> {getcon.cargo}</option>
             
                  ))
                 
             }

                           
              </select>
            
         </div>  */}





</div>


</div>
 </ModalBody>
 <ModalFooter style={{justifyContent:"center"}}>
   <button className="btn btn-primary btn-lg" onClick={()=>peticionPut()}><i class="fa-solid fa-pen-to-square"></i> Editar</button>{" "}
   <button className="btn btn-danger btn-lg" onClick={()=>abrirCerrarModalEditar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
 </ModalFooter>
</Modal>


<Modal isOpen={modalInsertar} style={{maxWidth:"800px"}}>
<ModalHeader  style={{backgroundColor:"#348fe2",color:"white"}} ><i class="fas fa-hands-helping"></i> <font Size="2">AGREGAR EMPRESA</font></ModalHeader>
 <ModalBody>
   <div className="form-group">

   <div className="col-12">

<center><label>-------------------------------------- TIPO DE EMPRESA --------------------------------------- </label> </center>
<br/>
</div>

   <div className="row">

<center>
   <div class="col-7">

   <select name="edad"  value={value2}  class="form-control btn btn-info" onChange={e => setValue2(e.currentTarget.value)} >
<option value='no' selected style={{color:'yellow'}}>-- SELECCIONAR --</option>
  <option value="rcomun">RÉGIMEN COMÚN</option>
  <option value="rsimplificado">RÉGIMEN SIMPLIFICADO</option>
  <option value="rordinario">RÉGIMEN ORDINARIO</option>
  
  
</select> 






  {/*<div class="col-sm-2">
  { value2==='Restriccion de edad' ? (<div>

    <div>
        
      <h1>Restricción de edad</h1>
    </div>
  </div>
  ):null}


  

  </div> */}
{/*
<label>Nombre completos:</label>
<br/>
      <input type="text" className="form-control"  name="name" onChange={handleChange}
  /> */}
  </div>

  </center>


  <div className="col-12">
  <br/>
<center><label>-------------------------------------- DATOS DE ACCESO --------------------------------------- </label> </center>
<br/>
</div>


{ value2==='rcomun' ? (<div class="col-5">


  <label>Tipo documento:</label>
  <br/>
    
<select name="tdoc" class="form-control"  onChange={handleChangetdoc} >
<option value='no' selected>-- Seleccionar --</option>
  <option value="NIT">(NIT) Número de identificación tributaria</option>
  
  
  
</select> 

</div>
):null}



{ value2==='rsimplificado' ? (<div class="col-5">


  <label>Tipo documento:</label>
  <br/>
    
<select name="tdoc" class="form-control" onChange={handleChangetdoc} >
<option value='no' selected>-- Seleccionar --</option>
  <option value="CC">(CC) Cedula de ciudadania</option>
  <option value="CE">(CE) Cedula de extranjeria</option>
  
  
  
</select> 

</div>
):null}


{ value2==='rordinario' ? (<div class="col-5">


  <label>Tipo documento:</label>
  <br/>
    
<select name="tdoc" class="form-control" onChange={handleChangetdoc}>
<option value='no' selected>-- Seleccionar --</option>
  <option value="CC">(CC) Cedula de ciudadania</option>
  <option value="CE">(CE) Cedula de extranjeria</option>
  <option value="NIT">(NIT) Número de identificación tributaria</option>
  
  
  
</select> 

</div>
):null}


<div class="col-3">

<label>Documento:</label>
<br/>
      <input type="text" className="form-control"  name="documento" onChange={handleChange}
        />
  </div>

  <div class="col-4">

  <label>Contraseña:</label>
  <br/>
  <input type="text"  className="form-control" required minLength={8} maxLength={15}  name="clave" onChange={handleChange} 
   />
   <label>Mínimo 8 caracteres</label>
  <br/>
  </div>


  <div class="col-2">

<label>Licencias:</label>
<br/>
      <input type="number" className="form-control"  name="licencia" onChange={handleChange}
        />
  </div>

  <div className="col-12">
  <br/>
<center><label>-------------------------------------- INFORMACIÓN EMPRESA --------------------------------------- </label> </center>
<br/>
</div>

<div class="col-6">

<label>Razón social:</label>
<br/>
      <input type="text" className="form-control"  name="name" onChange={handleChange}
        />
  </div>


  <div class="col-3">

<label>Teléfono:</label>
<br/>
      <input type="number" className="form-control"  name="celular" onChange={handleChange}
        />
  </div>


  <div class="col-3">

<label>Teléfono alternativo:</label>
<br/>
      <input type="number" className="form-control"  name="celular_alternativo" onChange={handleChange}
        />
  </div>


  <div class="col-4">
<br />
<label>Email:</label>
<br/>
      <input type="email" className="form-control"  name="email" onChange={handleChange}
        />
  </div>
 

  <div class="col-8">
  <br />
<label>Dirección:</label>
<br/>
      <input type="text" className="form-control"  name="direccion" onChange={handleChange}
        />
  </div>


  <div class="col-4">
  <br/>
<label>Departamento:</label>
<br/>
      <input type="text" className="form-control"  name="departamento" onChange={handleChange}
        />
  </div>


  <div class="col-4">
  <br/>
<label>Municipio:</label>
<br/>
      <input type="text" className="form-control"  name="ciudad" onChange={handleChange}
        />
  </div>


  <div class="col-4">
  <br/>
<label>URL web:</label>
<br/>
      <input type="text" className="form-control"  name="web" onChange={handleChange}
        />
  </div>


  { value2==='rcomun' ? (<><div class="col-4">


  <br/>
<label>Nombre del representante:</label>
<br/>
      <input type="text" className="form-control"  name="nombre_r" onChange={handleChange}
        />
</div>


<div class="col-4">

<br/>
<label>Tipo documento representante:</label>
<br/>
  
<select name="tdoc_r" class="form-control" onChange={handleChange} >
<option value='no' selected>-- Seleccionar --</option>
<option value="CC">(CC) Cedula de ciudadania</option>
<option value="CE">(CE) Cedula de extranjeria</option>



</select> 


</div>


<div class="col-4">


  <br/>
<label>Documento representante:</label>
<br/>
      <input type="text" className="form-control"  name="documento_r" onChange={handleChange}
        />
</div>


<div class="col-4">


  <br/>
<label>Celular representante:</label>
<br/>
      <input type="text" className="form-control"  name="celular_r" onChange={handleChange}
        />
</div>

<div class="col-8">


  <br/>
<label>Email representante:</label>
<br/>
      <input type="email" className="form-control"  name="email_r" onChange={handleChange}
        />
</div>


<div class="col-12">


  <br/>
<label>Dirección representante:</label>
<br/>
      <input type="text" className="form-control"  name="direccion_r" onChange={handleChange}
        />
</div>
</>
):null}


{ value2==='rsimplificado' ? (<><div class="col-4">


<br/>
<label>Nombre del representante:</label>
<br/>
    <input type="text" className="form-control"  name="nombre_r" onChange={handleChange}
      />
</div>



<div class="col-4">


<br/>
<label>Celular representante:</label>
<br/>
    <input type="text" className="form-control"  name="documento_r" onChange={handleChange}
      />
</div>

<div class="col-4">
<br/>


<label>Email representante:</label>
<br/>
    <input type="email" className="form-control"  name="email_r" onChange={handleChange}
      />
</div>


<div class="col-12">


<br/>
<label>Dirección representante:</label>
<br/>
    <input type="text" className="form-control"  name="direccion_r" onChange={handleChange}
      />
</div>
</>
):null}

  
{ value2==='rordinario' ? (<><div class="col-4">


<br/>
<label>Nombre del representante:</label>
<br/>
    <input type="text" className="form-control"  name="nombre_r" onChange={handleChange}
      />
</div>


<div class="col-4">

<br/>
<label>Tipo documento representante:</label>
<br/>

<select name="tdoc_r" class="form-control" onChange={handleChange}>
<option value='no' selected>-- Seleccionar --</option>
<option value="CC">(CC) Cedula de ciudadania</option>
<option value="CE">(CE) Cedula de extranjeria</option>



</select> 


</div>


<div class="col-4">


<br/>
<label>Documento representante:</label>
<br/>
    <input type="text" className="form-control"  name="documento_r" onChange={handleChange}
      />
</div>


<div class="col-4">


<br/>
<label>Celular representante:</label>
<br/>
    <input type="text" className="form-control"  name="celular_r" onChange={handleChange}
      />
</div>

<div class="col-8">


<br/>
<label>Email representante:</label>
<br/>
    <input type="email" className="form-control"  name="email_r" onChange={handleChange}
      />
</div>


<div class="col-12">


<br/>
<label>Dirección representante:</label>
<br/>
    <input type="email" className="form-control"  name="direccion_r" onChange={handleChange}
      />
</div>
</>
):null}


  {/*<div className="col-6">
    
   <br/>
                 <label>Agregar cargo:</label>
              <select name="cargo" className="form-select" onChange={handleChange}>
                   <option>--Seleccionar cargo--</option>
                   {
                     cargo.map( (getcon)=>(
                      
                   <option key={getcon.id} value={getcon.cargo}> {getcon.cargo}</option>
                
                     ))
                    
                }

                              
                 </select>
               
            </div>  */}
  
 
  
 

  </div>

   
   </div>
 </ModalBody>
 <ModalFooter style={{justifyContent:"center"}}>
   
   <button className="btn btn-primary btn-lg"  onClick={()=>peticionPost()}><i class="fa-solid fa-pen-to-square "></i> Guardar</button>{" "}
   <button className="btn btn-danger btn-lg" onClick={()=>abrirCerrarModalInsertar()}><i class="fa-solid fa-circle-xmark"></i> Cancelar</button>
  
 </ModalFooter>
</Modal>



</div>
   
  )
}

else if(!user){
    return"";
}

}

export default Empresas
