import {useState,useContext} from 'react';
import { Link } from 'react-router-dom';


import {MyContext} from './MyContext';

import Logo from '../../components/assets/login/logo.jpg';

import '../../index.css';


const Login = () => {

    const {loginUser, loggedInCheck} = useContext(MyContext);
    //Registrar
    const {registerUser, wait} = useContext(MyContext);
    const [redirect, setRedirect] = useState(false);
    const [errMsg, setErrMsg] = useState(false);
    const [successMsg, setSuccessMsg] = useState(false);
    const [recuperar_pass, setRecuperar_pass] = useState("VOLVER A INICIO");
   
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        documento:'',
        clave:''
    });

    

    const onChangeInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }

   
    const submitForm = async (e) => {
        e.preventDefault();

        if(!Object.values(formData).every(val => val.trim() !== '')){
            setErrMsg('Por favor, rellene todos los campos obligatorios!');
            return;
        }
        
         const data = await loginUser(formData);
        if(data.success){
            e.target.reset();
            setRedirect('Redirigir...');
            await loggedInCheck();
            return;
        }
        setErrMsg(data.message);
    }





    function loadData(){
        setLoading(false);

        setTimeout(()=>{
            setLoading(true);
            //alert("No se pudo crear la indicacion")
        }, 100);
    }

    const recuperar_password= (e) => {
        // console.log(e.target.value) // si || no
        setRecuperar_pass(e.target.value)
      }

  
    return(

    <>
    <div id="loader" className="app-loader">
    </div>
    
    
    <div id="app" className="app">
    
    <div className="login login-with-news-feed">
    
    <div className="news-feed">
    <div className="news-image Fondo" ></div>
    
    </div>
    
    
    <div className="login-container">
    <center><img src={Logo} width="180px" /></center>
    <div className="login-header mb-30px">
    
  
    </div>
    
    
    <div className="login-content">
    {recuperar_pass === "VOLVER A INICIO" && (
    <form className="fs-13px" onSubmit={submitForm} noValidate>
    <div className="form-floating mb-15px">
    <input name="documento" type="text" className="form-control h-45px fs-13px" placeholder="Usuario"
     value={formData.documento}  onChange={onChangeInput} autoComplete="off" />
    <label type="number" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Documento</label>
    </div>
    <div className="form-floating mb-15px">
    <input name="clave" type="password" className="form-control h-45px fs-13px" placeholder="Password" autoComplete="off"
     value={formData.clave}  onChange={onChangeInput} />
    <label for="password" className="d-flex align-items-center fs-13px text-gray-600">Contraseña</label>
    </div>
    
    
    <div className="mb-15px ">
    <button type="submit" className="btn btn-info d-block h-45px w-100 btn-lg fs-14px">INICIAR SESIÓN</button>
    </div>
    
    <div className='row'>
    
    <div className="mb-5px col-6 ">
    <input type="button" value="RECUPERAR CLAVE"  className="btn btn-primary d-block h-35px w-100 btn-lg fs-12px"  onClick={recuperar_password} />
    </div>
    
    <div className="mb-5px  col-6">
    <a href='https://gemcontsoft.com/apiGemcontsoft/solicitar_demo/' value="DEMO"  className="btn btn-primary d-block h-35px w-100 btn-lg fs-12px"> SOLICITAR DEMO</a>
    </div>
    </div>
    
   
    <div className="mb-40px pb-40px text-dark">
    </div>
    
    <center><h4>{errMsg}</h4></center>
   
    
    <hr className="bg-gray-600 opacity-2" />
    <div className="text-gray-600 text-center  mb-0">
    &copy; Todos los derechos reservados gemcontsoft.com
    </div>
    </form>
    
    )}
    
    <div className="col-sm-12">
            {recuperar_pass === "RECUPERAR CLAVE" && (
                <>
                <form className="fs-13px" method="post" action="https://gemcontsoft.com/apiGemcontsoft/recover_email/clave_usuarios.php" target="_parent" name="reset" noValidate>
              <div className="form-floating mb-15px">
              <input name="email" type="email" className="form-control h-45px fs-13px" placeholder="Usuario"
                autoComplete="off" />
              <label type="email" for="emailAddress" className="d-flex align-items-center fs-13px text-gray-600">Email</label>
              </div>
             
              <div className="mb-15px">
              <button type="submit" onClick={loadData} disabled={loading} className="btn btn-primary d-block h-45px w-100 btn-lg fs-14px">RECUPERAR CLAVE</button>
            
              </div>
            
    
              <div className="mb-40px pb-40px text-dark">
     
     <input
                type="button"
               
                value="VOLVER A INICIO"    
                className="btn btn-default"       
                onClick={recuperar_password}
              /> 
    </div> 
              
              
    <hr className="bg-gray-600 opacity-2" />
    <div className="text-gray-600 text-center  mb-0">
    &copy; Todos los derechos reservados gemcontsoft.com
    </div>
              </form>
              </>
              
            )}




          </div>
    
    </div>
    
    </div>
    
    </div>
    
    </div>
    
    
    
    <a href="javascript:;" className="btn btn-icon btn-circle btn-success btn-scroll-to-top" data-toggle="scroll-to-top"><i class="fa fa-angle-up"></i></a>
    
    
    </>

)
}

export default Login;