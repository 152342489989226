import React, {useState,useContext} from 'react';
import {MyContext} from '../login/MyContext';

function Header() {
   /* const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;*/
    const {user, logout} = useContext(MyContext);
  
    return (

<div id="header" className="app-header">

<div className="navbar-header">
<a href="index.html" className="navbar-brand"><img src="../assets/img/logo/logo-admin.png" /></a>
<button type="button" className="navbar-mobile-toggler" data-toggle="app-sidebar-mobile">
<span className="icon-bar"></span>
<span className="icon-bar"></span>
<span className="icon-bar"></span>
</button>
</div>


<div className="navbar-nav">
<div className="navbar-item navbar-form">
  
{/*<form action="#" method="POST" name="search">
<div className="form-group">
<input type="text" className="form-control" placeholder="Buscar" />
<button type="submit" className="btn btn-search"><i className="fa fa-search"></i></button>
</div>
</form> */}
</div>

<div className="navbar-item dropdown">
<div   className="navbar-link  icon">


</div> 

</div>
<div className="navbar-item navbar-user dropdown">
<a href="#" className="navbar-link dropdown-toggle d-flex align-items-center" data-bs-toggle="dropdown">
<img src="../assets/img/user/user-13.jpg" alt="" />
<span>
<span className="d-none d-md-inline">{user.name} <a href="#"  style={{textDecoration:"none", color:"black"}}></a></span>
<b className="caret"></b>
</span>
</a>
<div className="dropdown-menu dropdown-menu-end me-1">
{/*<a href="javascript:;" className="dropdown-item">Edit Profile</a>
<a href="javascript:;" className="dropdown-item d-flex align-items-center">
Inbox
<span className="badge bg-danger rounded-pill ms-auto pb-4px">2</span>
</a>
<a href="javascript:;" className="dropdown-item">Calendar</a>
    <a href="javascript:;" className="dropdown-item">Setting</a>*/}
<div className="dropdown-divider"></div>
<a className="dropdown-item" onClick={logout}> Cerrar Sesión</a>

</div>
</div>
</div>
<div style={{display:"none"}}>
{/*<input value={global.idprincipal = theUser.id} />*/}
</div>
</div>
  
        
    )
}

export default Header
