import React from 'react'

function Routes() {
    return <></>
  }
 
  Routes.global = {
    url_login: 'https://gemcontsoft.com/apiGemcontsoft/auth/'
  };
  
  export default Routes;
