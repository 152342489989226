import React, { createContext, useState, useEffect} from "react";
import axios from 'axios'
import Routes from '../routes/Routes';
export const MyContext = createContext();


const Axios = axios.create({
    baseURL: Routes.global.url_login
});


export const MyContextProvider = ({children}) => {

    const [theUser, setUser] = useState(null);
    const [wait, setWait] = useState(false);

   
  
    const registerUser = async ({documento,name,email,clave}) => {
        setWait(true);
        try{
            const {data2} = await Axios.post('register.php',{
                name,
                email,
                clave,
                documento 
            });
            setWait(false);
            return data2;
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error Demo!'};
        }
    }

    const loginUser = async ({documento,clave}) => {
        setWait(true);
        try{
            const {data} = await Axios.post('login.php',{
                documento,
                clave 
            });
            if(data.success && data.token){
                localStorage.setItem('loginToken', data.token);
                setWait(false);
                return {success:1};
            }
            setWait(false);
            return {success:0, message:data.message};
        }
        catch(err){
            setWait(false);
            return {success:0, message:'Server Error!'};
        }

    }

    const loggedInCheck = async () => {
        const loginToken = localStorage.getItem('loginToken');
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+loginToken;
        if(loginToken){
            const {data} = await Axios.get('getUser.php');
            if(data.success && data.user){
                setUser(data.user);
                return;
            }
            setUser(null);
        }
    }

    useEffect(() => {
        async function asyncCall(){
            await loggedInCheck();
        }
        asyncCall();
    },[]);

    const logout = () => {
        localStorage.removeItem('loginToken');
        setUser(null);
    }


    return (
        <MyContext.Provider value={{registerUser,loginUser,wait, user:theUser,loggedInCheck,logout}}>
            {children}
        </MyContext.Provider>
    );

}
export default MyContextProvider;





